
















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getRemoteUsers } from '@/api/helpers'
import { changeOwner } from '@/api/properties'
import { errorMsg, getTranslations, hasRoles, successMsg } from '@/utils'
import { FilterOperator, FilterType } from '@/utils/filter'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'ChangeOwnerDialog'
})

export default class extends Vue {
  @Prop({ required: true }) private propertyId!: any
  @Prop({ required: false }) private dialog!: boolean
  private usersLoading = false
  private users: any[] = []
  private userId: any = null
  private getTranslations = getTranslations

  private handleClose() {
    this.userId = null
    this.users = []
    this.$emit('close')
  }

  private async getRemoteUsersList(query: string) {
    let items: any = []
    this.usersLoading = true
    if (!hasRoles(['super_admin'], UserModule.roles)) {
      items = [{
        type: FilterType.field,
        key: 'office_id',
        value: UserModule.officeId,
        operator: FilterOperator.eq
      }]
    }
    this.users = await getRemoteUsers(query, items)
    this.usersLoading = false
  }

  private async changeItemsOwner() {
    try {
      if (!this.userId || !this.propertyId) {
        return
      }
      await changeOwner({
        id: this.propertyId,
        user_id: this.userId
      })
      await successMsg('actions.apiSuccessSave')
      this.$emit('success')
      this.handleClose()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }
}
