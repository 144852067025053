







































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  deleteProperty,
  deletePropertyBulk,
  exportProperties,
  copyProperty,
  getProperties,
  getPropertiesCount
} from '@/api/properties'
import {
  areasFilter,
  assignationTypeFilter,
  assignationStateFilter,
  availableForFilter,
  clientsFilter,
  createFilter,
  dateFilter,
  getSortItems,
  personalFilter,
  prepareListFilters,
  propertyCategoryFilter,
  propertyStateFilter,
  propertyStatusFilter,
  propertyTypeFilter,
  searchFieldConcat,
  simpleSearchField,
  SortOrder,
  stackedFieldsFilter,
  usersFilter, switchStateFilter, FilterType, FilterOperator, searchFieldConcatReverse
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import ChangeOwnerDialog from '@/views/property/components/ChangeOwnerDialog.vue'
import {
  debounce,
  parseTime,
  getTranslations,
  getAreaFullTitle,
  hasPermission,
  confirmDialog,
  successMsg,
  errorMsg,
  numberFormat
} from '@/utils'
import { getImagePreview, getPublicAndOfflineRequiredFilters, PropertyFloor, redirectToSite } from '@/utils/property'

@Component({
  name: 'PropertyList',
  components: {
    Pagination,
    ListToolbar,
    FiltersDrawer,
    ChangeOwnerDialog
  }
})

export default class extends Vue {
  private count = 0
  private total = 0
  private list = []
  private listLoading = true
  private showFilters = false
  private changeOwnerDialog = false
  private propertyId: any = null
  private search = ''
  private selected = []
  private listQuery = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private getTranslations = getTranslations
  private getAreaFullTitle = getAreaFullTitle
  private hasPermission = hasPermission
  private numberFormat = numberFormat
  private getImagePreview = getImagePreview
  private redirectToSite = redirectToSite
  private searchFields = [
    searchFieldConcat('user.'),
    searchFieldConcatReverse('user.'),
    searchFieldConcat('client.'),
    searchFieldConcatReverse('client.'),
    simpleSearchField('full_code'),
    simpleSearchField('codes_history'),
    {
      type: 'function',
      key: 'area.translations.title,area.translations.description',
      function_name: 'concat',
      options: {
        ignore_case: true,
        unaccent: true
      }
    },
    simpleSearchField('post_code'),
    {
      type: 'function',
      key: 'translations.address,address_number',
      function_name: 'concat',
      options: {
        ignore_case: true,
        unaccent: true
      }
    }
  ]

  private filters: any = [
    switchStateFilter({
      label: 'filters.activeProperties',
      custom: true,
      getValue: (items: any) => {
        items.push({
          type: FilterType.operator,
          operator: FilterOperator.and,
          items: getPublicAndOfflineRequiredFilters(true)
        })
      }
    }),
    availableForFilter(),
    propertyCategoryFilter(),
    propertyTypeFilter(),
    assignationTypeFilter(),
    propertyStateFilter(),
    clientsFilter(),
    areasFilter('area.id,area.parent_id,area.parent.parent_id'),
    stackedFieldsFilter({
      key: 'floor',
      label: 'filters.floor',
      type: 'double'
    }),
    stackedFieldsFilter({
      key: 'price',
      label: 'filters.price'
    }),
    stackedFieldsFilter({
      key: 'acreage',
      label: 'filters.acreage'
    }),
    stackedFieldsFilter({
      key: 'views',
      label: 'filters.views'
    }),
    stackedFieldsFilter({
      key: 'kitchens',
      label: 'filters.kitchens'
    }),
    stackedFieldsFilter({
      key: 'living_rooms',
      label: 'filters.living_rooms'
    }),
    dateFilter({
      key: 'available_from',
      label: 'filters.available_from'
    }),
    dateFilter()
  ]

  created() {
    this.getQueryParams()
    if (hasPermission(['LIST_OTHERS_PROPERTIES'])) {
      this.filters.splice(1, 0, personalFilter({
        key: 'owner',
        label: 'filters.personalProperties'
      }))
      this.filters.push(usersFilter())
    }
    this.getList()
  }

  private getQueryParams() {
    const query: any = this.$route.query || {}
    this.filters.push(propertyStatusFilter(query.status))
    this.filters.push(dateFilter({
      key: 'date_updated',
      label: 'propertyDetail.dateUpdated',
      from: query.from ? new Date(Number(query.from)) : null,
      to: query.to ? new Date(Number(query.to)) : null
    }))
    this.filters.splice(1, 0, assignationStateFilter(query.assignation_state))
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteProperty({
        id
      })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async copyItem(id: string) {
    const [data] = await confirmDialog('propertyList.copyPropertyContent')
    if (!data) return
    try {
      await copyProperty({ id, with_media: true })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private changePropertyOwner(id: string) {
    this.changeOwnerDialog = true
    this.propertyId = id
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private submitFilters(filters: any) {
    this.filters = filters
    this.restartPaging()
    this.getList()
  }

  private redirectToEdit(property: any, newTab = false) {
    if (newTab) {
      const routeData = this.$router.resolve({
        name: property.auction ?  'EditAuction' : 'EditProperty',
        params: { id:property.id }
      })
      return window.open(routeData.href, '_blank')
    }
    return this.$router.push( '/'+(property.auction ?  'auction' : 'property') +'/edit/' + property.id )
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deletePropertyBulk(this.selected.map((item: any) => {
        return item.id
      }))
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareFilters(filters: any) {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: this.searchFields,
      filters: filters,
      defaultSort: [{
        field: 'dateCreated',
        order: SortOrder.desc
      }]
    })
  }

  private redirectToEauction(row: any) {
    if (row.e_auction_link) {
      return window.open(row.e_auction_link, '_blank')
    }
  }

  private async filtersChanged(filters: any) {
    try {
      this.prepareFilters(filters)
      const { data } = await getPropertiesCount(this.listQuery)
      this.count = data || 0
    } catch (err) {}
  }

  private tableRowClassName(item: any) {
    if (item.row.auction) {
      return 'auction-row';
    }
    return '';
  }

  private handleCommand(command: string, id: string) {
    switch (command) {
      case 'download': {
        exportProperties(this.list)
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
      case 'delete_item': {
        this.deleteItem(id)
        break
      }
      case 'change_owner': {
        this.changePropertyOwner(id)
        break
      }
      case 'copy_property': {
        this.copyItem(id)
        break
      }
      case 'filters': {
        this.count = this.total
        this.showFilters = true
        break
      }
    }
  }

  private getActualStatus(property: any) {
    if (property.assignation_state === 'offline') {
      return this.$t('assignation_state.offline')
    }

    if (property.status === 'approved' && property.assignation_state) {
      return this.$t(`assignation_state.${property.assignation_state}`)
    }

    if (property.status) {
      return this.$t(`property_status.${property.status}`)
    }

    return null
  }

  private getCode(property: any) {
    let codeTxt = property.full_code
    if (property.codes_history) {
      codeTxt += ` ${this.$t('propertyList.previous_codes')} ${property.codes_history}`
    }
    return codeTxt
  }

  private getFloorTitle(row: any) {
    if (row.floor === undefined || row.floor === null) {
      return row.floor
    }
    if (row.floor >= 8) {
      return this.$t('floor.eighth_and_above')
    }
    if (PropertyFloor[row.floor]) {
      return this.$t('floor.' + PropertyFloor[row.floor])
    }
    return row.floor
  }

  private restartPaging() {
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters(this.filters)
      const { data } = await getProperties(this.listQuery)
      this.list = data.collection
      this.total = data.pagination.total_items
      this.count = data.pagination.total_items
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    this.restartPaging()
    this.debounceMethod()
  }
}
